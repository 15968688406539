/* Modal Overlay */
.Onlinemodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 999999 !important; 
}

/* Modal Details */
.Onlinemodal-details {
    background-color: white;
    padding: 40px;
    border-radius: 45px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.432);
    width: 800px;
    max-width: 90%;
    max-height: 100vh;
    overflow-y: auto;
    text-align: center;
    border-top: 8px solid #0056B3;
    position: relative;
    z-index: 100001; /* Above the overlay */
}


  .Onlinemodal-details p{
    font-size: 14px;
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 10px;
    
}
.Onlinemodal-details p a{
   color: #0056B3;
}
.Onlinemodal-details p a:hover{
    text-decoration: underline;
}
.Onlinemodal-details ul li{
    font-size: 14px;
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 10px;
    
}
  .Onlinemodal-details h3{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;
    font-weight: 500;
    margin-bottom: 2px;
    color: #08498f;
    font-size: 18px;
  }
  
  /* Close Modal Button */
  .close-Onlinemodal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #555;
    z-index: 100002;
  }
  
  .close-Onlinemodal:hover {
    color: #e60000;
  }
  
  /* Next/Close Button Container */
  .nextClose-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
  }
  
  .closePageBtnTerms {
    background-color: #5f5f5f;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    padding: 10px 20px;
    width: 30%;
    border-radius: 30px;
    transition: background-color 0.3s ease;
  }
  
  .closePageBtnTerms:hover {
    background-color: #dd3f3f;
  }


  @media screen and (max-width: 315px){
    .closePageBtnTerms {
        padding: 10px 10px;
        width: 60%;
       
      }
  }
  