/* General Container */
.check-rates-container-main{
    background-image: url('/public/images/profilebg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    flex-direction: column;
}
.check-rates-container {
    background-image: url('/public/images/container_bg2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50px;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}
/* Header Section *//* Header Section */
/* Header Section */

.check-rates-header{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #0f2853;
  border-radius: 50px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 3px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-image: url('/public/images/ratebg.jpg  ');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40vh;
  width: 100%;
}

.h1_check{
  font-size: 36px;
  margin-bottom: 10px;
  color:#bcd2f8;
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}
/* Country Selection */
  .dropdown-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background: linear-gradient(4deg, #104163, #6fc7ff);
    border-radius: 20px;
    color: white;
    justify-content: center;
    
  }
  
  .dropdown-header .flag {
    width: 20px;
    height: 15px;
    margin-right: 10px;
  }
  
  .dropdown-header .arrow {
    margin-left: auto;
    font-size: 12px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
    list-style: none;
    padding: 0;
    display: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-item .flag {
    width: 20px;
    height: 15px;
    margin-right: 10px;
  }
  
  .dropdown-item .country-name {
    font-size: 14px;
    color: #333;
  }

  .country-select-rates {
    position: relative;
    width: 200px;
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .toCountrycontainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: linear-gradient(4deg, #104163, #6fc7ff);
    border-radius: 20px;
    color: white;
    width: 200px;
    justify-content: center;

  }
  .toCountrycontainer .flag{
    width: 20px;
    height: 15px;
    margin-right: 10px;
  }


.form-group label{
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    color: #0056b3;
    
}

/* Arrow Icon */
.arrow-down {
    margin-left: 25px;
    margin-right: 25px;
    
}


/* Shipping Method */
.shipping-method-container {
    margin-top: 40px;
    text-align: center;
}

.shipping-method-container h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}

.shipping-options {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}

.shipping-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: #999;
    padding: 10px 20px;
    transition: color 0.3s, box-shadow 0.3s;
}

.shipping-option.active {
    color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.shipping-option i {
    font-size: 24px;
    margin-bottom: 8px;
}

.shipping-option:hover {
    color: #007bff;
}

/* Weight Section */
.weight-section {
    margin-top: 30px;
    text-align: left;
    background-color: #fff;
    padding: 25px;
    border-radius: 30px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
    justify-content: center;
}


.info-message-weight{
    background-color: #d9edf7;
    color: #31708f;
    padding: 10px 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 15px;
    border: 2px solid #9cd3df79;
    text-align: left;
    margin-bottom: 10px;
  }
  

.weight-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 25px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    color: #0f2853;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s, color 0.3s;
}

.tab.active-tab {
    color: #1c79dd;
    border-bottom: 2px solid #007bff;
}
.weightGuide{
    display: flex;
    align-items: center;
    justify-content: center;
}
.weightmeasureGuide{
    display: flex;
    flex-direction: row;
}
.weightmeasureGuide img{
    width:450px; 
    height: 450px;
}
.calPanel{
    background-color: #e9e9e9;
    margin-top: 20px;
    padding: 15px;
    border-radius: 35px;
}

.weight-input-section label{
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    color:#0f2853;
    margin-right: 10px;
}
.actualWeight-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    
}
.weight{
    align-items: center;
    width: 200px;
    padding: 7px;
    font-size: 14px;
    color: #0f2853;
    border: 1px solid #0f2853;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    border-radius: 15px;
    outline: none;

}

.volumetric-input-section {
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}
.volWeight_inputSection{
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
}

.input-row {
    margin-bottom: 5px;
    
    
}

.input-row label {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #0f2853;
    margin-left: 10px;
    
}

.input-row input {
    width: 80px;
    padding: 7px;
    font-size: 14px;
    color: #0f2853;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: 1px solid #ddd;
    border-radius: 15px;
    border: 1px solid #0f2853;
    outline: none;
}

.volumetric-weight-result{
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
.volumetric-weight-result label{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #0f2853;
    margin-bottom: 2px;
    
}
/* Volumetric Weight Result */
.volumetric-weight-result input {
    width: 120px;
    padding: 12px;
    height: 40px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 15px;
    background-color: #f5f5f5;
    color: #888;
    outline: none;
}
.volumetric{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.billable{
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Calculate Button */
.calculate-button {
    background-color: #0f2853;
    color: white;
    border: none;
    justify-content: center;
    align-self: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    width:45%;
    margin-top: 15px;
    padding: 10px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.calculate-button:hover {
    background-color: #054ac0;
}


.shopBtn {
    background-color: #0b4206;
    color: white;
    border: none;
    justify-content: center;
    align-self: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    width:40%;
    margin-top: 30px;
    padding: 10px;
    border-radius: 25px;
    cursor: pointer;
    transition: 0.3s background-color ease;
}

.shopBtn:hover {
    background-color: #20a114;
}

/* Price Section */
.price-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 25px;
}
.surcharge p {
    
    margin-top: 30px;
    color: #616161;
    margin-bottom: 0;
    text-align: left;
}
.surcharge p a{
    color: #0056b3;
}

.surcharge p a:hover{
    text-decoration: underline;  
}
.price-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    font-size: 18px;
    color: #0056b3;
    font-weight: bold;
}

.price-item img.flag {
    width: 40px;
    height: 30px;
    border-radius: 5px;
}

/* Rate Table Section */
.rate-form{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}
.rate-table-section {
    margin-top: 10px;
    background-color: #fff;
    padding: 20px;
    border-radius: 35px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.rate-table {
    width: 100%;
    border-collapse: separate; /* Switch from collapse to separate for border-radius to work */
    border-spacing: 0;
    margin-bottom: 15px;
    border-radius: 25px; 
    overflow: hidden; /* Ensures the border-radius is applied */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.rate-table th, .rate-table td {
    padding: 14px 20px;
    text-align: center; /* Center-align the table data */
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: 1px solid #ddd;
    font-weight: normal;
    color: #0b5cb3;
}


.rate-table th {
    background-color: #083F7A;
    color: #A5D2F8;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    border-right: 1px solid #ddd;
}

.rate-table td {
    border-right: 1px solid #ddd;
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: 15px;
    
}

/* header top corners curved*/
.rate-table th:first-child {
    border-top-left-radius: 10px;
}

.rate-table th:last-child {
    border-top-right-radius: 10px;
}

/* last row bottom corners curved */
.rate-table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.rate-table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
.rate-table tbody tr{
    background-color: #62a6cf; /* Set the default background color for rows */

}

/* Optional: Hover effect for rows */
.rate-table tbody tr:hover {
    background-color: #c9e9e6;
    cursor: pointer;
}


.table-note {
    margin-top: 15px;
    background-color: #e6f7ff;
    padding: 12px;
    border-radius: 5px;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #007bff;
}


.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }


  @media screen and (max-width: 480px){
    .weightmeasureGuide{
        display: flex;
        flex-direction: column;
    }
    .weightmeasureGuide img{
        width:300px; 
        height: 300px;
    }
    .actualWeight-container{
        flex-direction: column;
    }
    .weight{  
        width: 200px;
    }
    .volWeight_inputSection{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
    }
    .input-row label {
        visibility: hidden;
    }
   
    .input-row input {
        width: 300px;
    }
  
    .country-select-rates,
  .toCountrycontainer {
    width: 120px;
  }

  .toCountrycontainer .flag,
  .dropdown-header .flag,
  .dropdown-item .flag {
    width: 12px;
    height: 10px;
  }

  .dropdown-item .country-name {
    font-size: 15px;
  }

  .dropdown-header,
  .toCountrycontainer {
    padding: 6px;
  }

  .form-group label {
    font-size: 15px;
  }

  }


  @media screen and (max-width: 600px){
    .calculate-button{
        width: 40%;
    }
    .weightmeasureGuide{
        display: flex;
        flex-direction: column;
    }
    .weightmeasureGuide img{
        width:400px; 
        height: 400px;
        align-self: center;
    }
    .actualWeight-container{
        flex-direction: column;
    }
    .weight{  
        width: 300px;
    }
    .volWeight_inputSection {
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center; 
        width: 100%; 
        gap: 10px; 
    }
    
    .input-row {
        display: flex;
        justify-content: center; 
        width: 100%; 
        gap: 10px;
    }
    
    .input-row input {
        width: 100px; 
        max-width: 90%; 
        text-align: center; 
        padding: 8px; 
        box-sizing: border-box; 
    }
    
    .input-row label {
        display: none;
    }
}
@media (max-width: 1024px) {
    
  }
  
  