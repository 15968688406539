/* SignIn.css */
.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  height: 100vh; /* Full viewport height */
  background-image: url('/public/images/signinbg.webp');
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
  background-color: #1A2030;
  position: relative;
}

/* Back Button Container */
.back-button-container {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px; /* Adjusted size */
  height: 60px; /* Adjusted size */
  background-color: #4285F4; /* Background color for the back button */
  border-radius: 50%; /* Make it circular */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add a shadow for depth */
  cursor: pointer;
  z-index: 1000; /* Ensure it stays on top of other elements */
  transition: background-color 0.5s ease; /* Smooth hover effect */
}

.back-button-container:hover {
  background-color: #ec3332; /* Darker background on hover */
}

.top-right-back-button {
  width: 30px;
  height: auto;
  pointer-events: all;
}

.logo-image {
  width: 200px; /* Adjusted size for smaller screens */
  height: auto;
}

.logo-header {
  background-color: #e60000;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
}

.signin-form {
  background-color: white;
  padding: 20px; /* Reduced padding */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 320px; /* Reduced width */
  max-width: 90%; /* Ensure it doesn't exceed screen width */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.5);
}

p {
  color: #1A2030;
  font-size: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h2 {
  margin-top: 10px;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #1f468b;
}

.signup-link {
  color: red;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 10px;
}

.signup-link:hover {
  text-decoration: underline;
}

.input-group {
  margin-top: 18px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 15px;
}

label {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  color: #181818;
}

input {
  width: 100%;
  padding: 8px; /* Adjusted padding */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.forgot-password {
  text-align: right;
  margin-bottom: 80px; /* Reduced space */
  margin-top: -15px;
  width: 100%;
}

.forgot-password a {
  color: red;
  cursor: pointer;
  font-size: 12px;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

.signin-btn {
  width: 100%;
  padding: 10px;
  background-color: #ec3332;
  color: white;
  border: none;
  border-radius: 15px;
  font-size: 14px; /* Adjusted font size */
}

.signin-btn:hover {
  background-color: #ed0d0c;
}

/* Google Sign-In Button */
.google-signin-btn {
  background-color: #4285F4; /* Google Blue */
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  border: none;
  border-radius: 15px;
  font-size: 14px; /* Adjusted font size */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .signin-form {
    width: 40%; /* Maximum use of available space */
    padding: 35px;
  }

  h2 {
    font-size: 18px; /* Adjust title size */
  }

  input {
    font-size: 12px; /* Adjust input font size */
    padding: 8px;
  }

  .signin-btn,
  .google-signin-btn {
    font-size: 12px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .signin-form {
    width: 65%; /* Maximum use of available space */
    padding: 35px;
  }

  h2 {
    font-size: 16px;
  }

  input {
    font-size: 11px;
    padding: 6px;
  }

  .signin-btn,
  .google-signin-btn {
    font-size: 14px;
    padding: 11px;
  }
}
@media (max-width: 600px){
  .signin-form {
    width: 55%; /* Maximum use of available space */
    padding: 35px;
  }
}
