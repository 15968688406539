/* General Container Styling */
.archive-container-main {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    flex-direction: column;
    background-image: url('/public/images/profilebg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.archive-container {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    padding-bottom: 100px;
    font-family: 'Arial', sans-serif;
    background-image: url('/public/images/container_bg2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex: 1;
    border-radius: 50px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Welcome Section */
.welcome-section-archived {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 30px;
}

.welcome-section-archived h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    color: #bcd2f8;
    border-radius: 50px;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 3px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background-image: url('/public/images/archiveTitle_bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 40vh;
    width: 100%;
}

/* Shopping Requests Section - Table Styling */
.shopping-requests-section-container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.shopping-requests-section {
    margin-top: 10px;
    margin-bottom: 30px;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0; 
    border-radius: 10px; 
    overflow: hidden; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.table th, .table td {
    padding: 15px;
    border: 1px solid #f0e9e9;
    text-align: left;
}

.table th {
    background-color: #15405e;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    color: #ffffff;
    text-align: left;
}

/* Border-radius to the top left and top right corners */
.table th:first-child {
    border-top-left-radius: 10px;
}

.table th:last-child {
    border-top-right-radius: 10px;
}

/* Remove background color from individual td */
.table td {
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: 15px;
}

/* Apply background color to entire row */
.table tbody tr {
    background-color: #62a6cf; /* Set the default background color for rows */
}

/* Border-radius to the bottom left and bottom right corners */
.table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.table tbody tr:nth-child(even) {
    background-color: #62a6cf; /* Keep the default background color */
}

/* Hover effect applied to row */
.table tbody tr:hover {
    background-color: #c9e9e6; /* Change to #f2f2f2 on hover */
    cursor: pointer;
}





.no-requests-message {
    text-align: center;
    font-size: 18px;
    color: #ff6b6b;
    padding: 20px;
    background-color: #ffe3e3;
    border-radius: 8px;
    margin-top: 20px;
}
/* Info Box Styling */
.info-box-archive {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #E64032;
    color: #ffffff;
    border-radius: 30px;
    padding: 10px;
    margin-top: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 60%;
}

.info-icon-archive {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.info-icon-archive svg {
    fill: #ffffff;
}
  
.info-box-archive p {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 500;
}


/* Mobile Optimizations */
@media screen and (max-width: 600px) {
   
    .welcome-section-archived h1{
        font-size: 22px !important;
        height: 150px;
    }
    .info-box-archive {
        flex-direction: column; /* Stack elements vertically */
        width: 40%; /* Adjust width to fit mobile screen */
        top: 45%; /* Adjust top positioning for better view */
        padding: 5px; /* Add more padding for touch devices */
    }

    .info-icon-archive {
        margin-right: 0;
        margin-bottom: 10px; /* Add spacing between icon and text */
    }

    .info-box-archive p {
        font-size: 12px; /* Decrease font size for better readability */
        text-align: center; /* Center align text */
    }
}