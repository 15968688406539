.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.856);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 25px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .modal h3 {
    margin-top: 0;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #005F9E;
    font-size: 28px;
  }
  
  .modal textarea {
    width: 100%;
    height: 100px;
    margin: 10px 0;
    resize: none;
    border: solid 2px #005F9E;
    border-radius: 10px;
  }
  .modal textarea:hover{
    border: solid 2px #d9534f;

  }
  .modal-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 35px;
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .modal-buttons button:first-child {
    background: #005F9E;
    transition: background-color 0.3s ease;
    color: white;
  }
  .modal-buttons button:first-child:hover {
    background: #014470;
  }
  
  .modal-buttons button:last-child {
    background: #d9534f;
    color: white;
    transition: background-color 0.3s ease;
  }
  .modal-buttons button:last-child:hover {
    background: #be3935;
    
  }
  