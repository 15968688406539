/* Styles for the reset password page */

.resetPassword-main {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    height: 100vh;
    background-color: #f2f2f2; 
    background-image: url('/public/images/signupbg.jpg');
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    background-color: #1A2030;
  }
  .reset-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 350px;
  }
  .reset-form h1{
    text-align: center; 
    color: #333;
    font-size: 28px;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  }

  .resetPassword-main input {
    width: 300px;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .resetPassword-main input:focus {
    outline: none;
    border-color: #0077b6;
    box-shadow: 0 0 5px rgba(0, 119, 182, 0.5);
  }
  
  .resetBtn {
    width: 100%;
    padding: 10px;
    background-color: #ec3332;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
  }
  
  .resetBtn:hover {
    background-color: #005f9e;
  }
  
  .resetBtn:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 119, 182, 0.5);
  }
  
  /* Optional: Mobile responsiveness */
  @media (max-width: 768px) {
    .resetPassword-main input, 
    .resetBtn {
      width: 80%;
    }
  }
  