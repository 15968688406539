/* Full height of the page with flexbox */
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* Main content should take available space before the footer */
main {
  flex: 1;
}


/* General Footer Styles */
.footer {
  background-color: #1c1e22;
  color: white;
  width: 100%;
  border-top: 5px solid #434549;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 0; /* Remove unnecessary padding */
  min-height: 200px; /* Set a min-height for the footer */
}

/* Footer visible state */
.footer.visible {
  transform: translateY(0); /* Slide it up into view */
  visibility: visible;
}

/* Footer Container (Main Footer Content) */
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 40px 20px; /* Add padding only here */
  flex-wrap: wrap;
}

/* Footer Section */
.footer-section {
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
}

.footer-section h4 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #c4c3c3;
}

/* Logo Section */
.logo-section {
  text-align: left;
  flex: 0 0 25%;
  margin-right: 130px;
}

.footer-logo {
  width: 300px;
  height: auto;
}

/* Navigation Section */
.navigation-section ul {
  list-style: none;
  padding: 0;
  margin-left: 20px;
}

.navigation-section ul li {
  margin: 10px 0;
}

.navigation-section ul li a {
  color: #a19a9a;
  text-decoration: none;
}

.navigation-section ul li a:hover {
  color: #e7e7e7;
}

/* Contact Section */
.contact-section ul {
  list-style: none;
  padding: 0;
  color: #a19a9a;
}

.contact-section i {
  color: #a19a9a;
}

.contact-section i:hover {
  color: #e7e7e7;
}

.contact-section ul li {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.contact-section ul li i {
  margin-right: 10px;
}

.contact-section ul li a {
  color: #a19a9a;
  text-decoration: none;
}

.contact-section ul li a:hover {
  color: #e7e7e7;
}

/* Social Media Section */
.social-section {
  text-align:left;
}

.social-section a {
  margin-right: 8px;
  color: #a19a9a;
  font-size: 24px;
}

.social-section a:hover {
  color: #e7e7e7;
}

/* Footer Bottom Section */
.footer-bottom {
  background-color: #434549;
  text-align: center;
  padding: 10px 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-top: 4px solid #252525;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto; /* Ensure it stays at the bottom */
}

.footer-bottom p {
  color: #a19a9a;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}