/* shopOnline.css */


/* Confimation Box */
.css-rvoa5x-MuiTypography-root-MuiDialogTitle-root{

  color: #0056B3;
}
.MuiDialogTitle-root+.css-kw13he-MuiDialogContent-root{
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  size: 14px;
  font-weight: 400;
  color: #242424;
}

/* Ensure SweetAlert2 modal appears on top */
.swal2-popup-custom {
  position: fixed !important;  /* Force fixed positioning */
  z-index: 9999 !important;  /* Make sure it's above everything */
}

/* Ensure SweetAlert2 backdrop is positioned correctly */
.swal2-backdrop-custom {
  position: fixed !important;  /* Make sure backdrop is fixed */
  z-index: 9998 !important;  /* Just below the popup */
}

/* Modal Overlay */
.Onlinemodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050 !important;
  }
  
  /* Modal Details */
  
  .Onlinemodal-details {
    background-color: white;
    padding: 40px;
    border-radius: 45px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.432);
    width: 800px;
    max-width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    text-align: center;
    border-top: 8px solid #0056B3;
    position: relative; /* Set position relative to contain the close button */
  }
  .Onlinemodal-details h2{
    font-size: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #0056b3;
    margin-bottom: 10px;
    font-weight: 700;
  }
  .Onlinemodal-details steps{    
    font-size: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #000000;
    margin-bottom: 30px;
    font-weight: 700;
  }
  
  /* Close Modal Button */
  .close-Onlinemodal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #555;
  }
  
  .close-Onlinemodal:hover {
    color: #e60000;
  }
  
  /* Form Group */
  .Onlineform-group {
    margin-bottom: 20px;
    color: #252627;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
  }
  
  
  .Onlineform-group info {
    font-size: 14px;
    color: #555;
  }
  
  .Onlineform-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 15px;
    margin-bottom: 10px;
  }
  .Onlineform-group select{
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 35px;
    cursor: pointer;
  }

  /*Progress Bar*/
  .upload-progress {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  position: relative;
}

.progress-bar {
  height: 10px;
  background-color: #0056B3;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.upload-progress p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #333;
  margin: 0;
}


  /* Button Container */
  .nextClose-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    
  }
  
  .nextPageBtn{
    background-color: #0056B3;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    padding: 10px 20px;
    width: 30%;
    border-radius: 30px;
    
    transition: background-color 0.3s ease;
  }
  .nextPageBtn:hover{
    background-color: #0485db;
  }
  .closePageBtn{
    background-color: #5f5f5f;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    padding: 10px 20px;
    width: 30%;
    border-radius: 30px;
    
    transition: background-color 0.3s ease;
  }
  .closePageBtn:hover{
    background-color: #dd3f3f;
  }
  .prevPageBtn{
    background-color: #5f5f5f;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    padding: 10px 20px;
    width: 30%;
    border-radius: 30px;
    transition: background-color 0.5s ease;
  }
  .prevPageBtn:hover{
    background-color: #0485db;
  }
  .submitFromBtn{
    background-color: #0056B3;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    padding: 10px 20px;
    width: 30%;
    border-radius: 30px;   
    transition: background-color 0.5s ease;
  }
  .submitFromBtn:hover{
    background-color: #248f41;
  }
  .loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
  
  /* Error Styling */
  .input-error {
    border: 1px solid red;
  }
  