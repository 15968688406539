/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.74); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal appears on top */
}

/* Popup Content */
.popup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popup-content .popupSteps1,
.popup-content .popupSteps2,
.popup-content .popupSteps3 {
    color: #0056B3;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.packageInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-radius: 35px;
    background-color: #0056B3;
    background-image: url('/public/images/packageOpen.webp');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 100%;
    box-sizing: border-box; /* Ensure padding is included in width */
}

.packageInfo .packInfo {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    color: #e0e0e0;
    text-align: center;
    font-weight: 400;
}
.rate-display-container p{

}
.rate-display {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;
    border-radius: 30px;
    padding: 13px;
    width: 100%; /* Make width responsive */
    max-width: 400px;
    box-sizing: border-box; /* Ensure padding is included in width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.233);
}
.rate-display .rateDis {
    color: #e3eafa;
    font-size: 15px;
    background-color: #0056B3;
  
}


.checkbox-flex {
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertically center both the checkbox and label */
    justify-content: center; /* Horizontally center the entire section */
    gap: 5px; /* Adjust the gap between checkbox and label */
    margin-top: 20px;
}

.checkbox-flex input[type="checkbox"] {
    margin: 0; 
    transform: scale(1.2); 
    margin-left: 200px;
}

.checkbox-flex .termsLabel2 {
    font-size: 13px;
    color: #4d4d4d; /* Ensure text color is appropriate */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    text-align: left; /* Align text to the left */
    margin-right: 200px;
    white-space: nowrap;
}

/* Progress Bar */
.progress-bar-shipitem {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #0056B3;
    width: 0; /* Initial width */
    transition: width 0.3s ease-in-out;
  }
  
.popup-content label{
    font-size: 15px;
    color: #104163;
    font-weight: 500;
}

/* Buttons */
.btnNext,
.btnBack,
.btnSubmit {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 15px;
    width: 90px;
    border-radius: 25px;
    color: #e0e0e0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 17px;
    font-weight: 400;
    transition: background-color 0.3s ease-in-out;
}
.btnSubmitConsolidation{
    padding: 15px;
    width: 90px;
    border-radius: 25px;
    background-color: #019B19;
    color: #e0e0e0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 17px;
    font-weight: 400;
    transition: background-color 0.3s ease-in-out;
}
.btnSubmitConsolidation:hover{
    background-color: #00b318;
}
.btnNext {
    background-color: #0056B3;
    
}
.btnNext:hover {
    background-color: #0590e0;
}

.btnBack {
    background-color: #0a4380;
}
.btnBack:hover {
    background-color: #05a2e0;
}

.btnSubmit {
    background-color: #0858ad;
}
.btnSubmit:hover {
    background-color: #05a2e0;
}

.btnStep2-container,
.btnStep3-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 17px;
    gap: 10px;
}

.radio-address {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 15px;
    background-color: #536a96;
    border-radius: 25px;
    cursor: pointer; /* Change cursor to indicate clickability */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover and selection effects */
  }
  
  .radio-address span {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.4;
    word-wrap: break-word;
  }
  
  .radio-address:hover {
    background-color: #2f4d75; /* Slightly darker for hover effect */
    transform: scale(1.02); /* Slightly enlarge on hover */
  }
  
  .radio-address.selected {
    background-color: #2f4d75; /* Different color for selected address */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow for emphasis */
  }
  

  .add-new-address-container{
    display: flex;
    flex-direction: row;
  }
  .btnAddaddress{
    background-color: #286e33;
    padding: 13px;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    border-radius: 25px;
    transition: ease background-color 0.3s;
  }
  .btnAddaddress:hover{
    background-color: #298b38;
  }
.add-address-form{ 
    margin-top: 10px;
    background-color: #2F4D75;
    padding: 10px;
    border-radius: 25px;
}
.add-address-form h4{
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: white;
    font-size: 25px;  
    font-weight: 500;
}
.input-group-address-consolidation label{
  display: block;
  margin-top: 10px;
  font-weight: 500;
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}
.contact-number-input{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.country-code{
    color: rgb(199, 199, 199);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    
}
.addressAddBtn-Container{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    gap: 10px;
}

.btnAdd{
    background-color: #078318;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    padding: 10px 10px;
    border-radius: 20px;
    transition: background-color 0.3s ease;
}
.btnAdd:hover{
    background-color:#00b318 ;
}
.btnCancel{
    background-color: #3b3b3b;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    padding: 10px 35px;
    border-radius: 20px;
    transition: background-color 0.3s ease;
}
.btnCancel:hover{
    background-color: #b91f1f;
}


/* Close Button */
.closeSteps-btn {
    position: relative;
    display: flex;
    justify-self: right;  
    font-size: 35px;   
    cursor: pointer;
    color: #333;
    background: none;    
    border: none;        
}

.closeSteps-btn:hover {
    color: #e60000; /* Hover effect */
}

/* Responsive Adjustments for Tablets and Mobile Devices */
@media screen and (max-width: 768px) {
    .packageInfo {
        background-size: 50%; /* Adjust background image size */
        padding: 15px; /* Reduce padding for smaller screens */
    }

    .packageInfo p,
    .rate-display p {
        font-size: 14px; /* Reduce font size */
    }

    .rate-display {
        padding: 8px;
    }

    .checkbox-flex input[type="checkbox"] {
        margin-left: 50px; /* Reduced margin for smaller screens */
    }

    .checkbox-flex .termsLabel2 {
        margin-right: 50px; /* Adjust margin for smaller screens */
        font-size: 12px; /* Slightly smaller font size */
    }

    .btnNext,
    .btnBack,
    .btnSubmit {
        width: 80px; /* Smaller button size */
        font-size: 15px;
        padding: 12px;
    }
}

/* Media query for mobile devices (576px and smaller) */
@media screen and (max-width: 576px) {
    .checkbox-flex {
        flex-direction: column; /* Stack the checkbox and label vertically on small screens */
        align-items: center; /* Align items to the left */
    }

    .checkbox-flex input[type="checkbox"] {
        margin-left: 0; /* Remove margin on mobile devices */
        transform: scale(1); /* Adjust checkbox size for smaller screens */
    }

    .checkbox-flex .termsLabel2 {
        margin: 0; /* No margin for mobile devices */
        font-size: 12px; /* Smaller font for mobile */
    }

    .packageInfo {
        background-size: 100%; /* Further adjust background image size */
        padding: 10px; /* Reduce padding even more for mobile */
    }

    .packageInfo p,
    .rate-display p {
        font-size: 12px; /* Further reduce font size for mobile */
    }

    .btnNext,
    .btnBack,
    .btnSubmit {
        width: 75px; /* Smaller button size */
        font-size: 14px;
        padding: 10px;
    }
}
