.viewAddresses{
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-items: center;
    align-self: center;
    background-color: #0077B6;
    padding: 8px;
    border-radius: 15px;
    transition: background-color 0.3s ease;
}
.viewAddresses:hover{
    background-color:#035f91 ;
}
.viewAddressBtn{
    color: white;
}