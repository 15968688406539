/* Default Styles (Desktop) */
.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #1a2030;
  background-image: url('/public/images/signupbg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  box-sizing: border-box;
}

.logo-image {
  width: 180px;
  height: auto;
}

.signup-form {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

p {
  color: #1a2030;
  text-align: center;
  font-size: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h2 {
  text-align: center;
  color: #333;
  font-size: 20px;
  font-weight: 600;
}

.signin-link {
  color: red;
  cursor: pointer;
}

.signin-link:hover {
  text-decoration: underline;
}

.input-group {
  margin-bottom: 20px;
  width: 100%;
}

label {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  color: #181818;
}

input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 14px;
  box-sizing: border-box;
}

.phone-input {
  display: flex;
  align-items: center;
}

.phone-input span {
  margin-right: 10px;
  font-weight: 500;
  color: #696969;
}

.next-btn,
.back-btn,
.signup-btn {
  width: 100%;
  padding: 10px;
  background-color: #3264ec;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
}

.next-btn:hover,
.back-btn:hover,
.signup-btn:hover {
  background-color: #1c55f3;
}

.signup-btn {
  background-color: #ec3332;
}

.signup-btn:hover {
  background-color: #ed0d0c;
}

.signup-btn:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.password-strength-bar {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
}

.password-strength-level {
  height: 100%;
  border-radius: 5px;
}

.level-25 {
  background-color: red;
}

.level-50 {
  background-color: orange;
}

.level-75 {
  background-color: #FFBA00;
}

.level-100 {
  background-color: #228B41;
}

.password-strength-text {
  font-size: 13px;
  margin-top: 5px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: #333;
  text-align: left;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .signup-form {
    max-width: 400px;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .signup-container {
    padding: 10px;
  }

  .signup-form {
    width: 90%;
    padding: 15px;
  }

  input {
    font-size: 14px;
    padding: 8px;
  }

  h2 {
    font-size: 18px;
  }

  .next-btn,
  .back-btn,
  .signup-btn {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .signup-form {
    width: 70%;
    padding: 20px;
  }

  h2 {
    font-size: 16px;
  }

  input {
    font-size: 12px;
    padding: 8px;
  }

  .next-btn,
  .back-btn,
  .signup-btn {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .signup-form {
    width: 60%;
    padding: 20px;
  }
}
