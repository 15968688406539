.main-container{
  background-image: url('/public/images/profilebg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  flex-direction: column;
}

.profile-container {
  width: 90%;
  max-width: 1200px; /* Ensure a maximum width for large screens */
  margin: auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* User info section */
.user-info {
  border-radius: 50px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-image: url('/public/images/welcomebg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100%; /* Ensure full width for the container */
}

.user-info h2 {
  margin-bottom: 10px;
  font-size: 35px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: #0f2853;
  text-align: center;
}

/* Status section */
.status {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 5px 10px;
}

.status-active {
  background-color: #28a745;
  padding: 5px 10px;
  color: white;
  border-radius: 15px;
}

/* Parent info-item container */
.parent-info-item {
  background-color: #ece9e9;
  border-radius: 50px;
  width: 50%;
  max-width: 600px; /* Ensure a maximum width for large screens */
  padding: 20px;
  margin: auto;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.5);
}

.info-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #0f2853;
  font-weight: 500;
  margin-bottom: 10px;
}

.info-item i {
  margin-right: 10px;
}

/* Media queries for mobile optimization */

/* For small screens (up to 600px) */
@media (max-width: 600px) {
  .profile-container {
    width: 100%; /* Full width on mobile */
    padding: 0 10px; /* Reduce padding */
  }

  .user-info {
    border-radius: 20px; /* Less border radius for mobile */
    padding: 15px;
    margin-top: 10px;
    height: auto; /* Let the height adjust based on content */
  }

  .user-info h2 {
    font-size: 24px; /* Smaller font size for mobile */
    margin-bottom: 5px;
  }

  .status {
    margin-bottom: 10px;
    padding: 5px;
  }

  .parent-info-item {
    width: 90%; /* Make it nearly full width on mobile */
    padding: 15px;
    border-radius: 20px; /* Less rounded for mobile */
    margin-top: 20px;
  }

  .info-item {
    font-size: 14px; /* Smaller font for mobile */
    margin-bottom: 8px;
  }
}

/* For tablets (up to 768px) */
@media (max-width: 768px) {
  .profile-container {
    width: 90%; /* Slightly reduced width for tablet */
  }

  .user-info h2 {
    font-size: 28px; /* Slightly smaller font size */
  }

  .parent-info-item {
    width: 70%; /* Slightly smaller for tablets */
  }
}


/* Address section and cards */
.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Centers cards in a row */
  
}

.address-section-profile {
  background-color: #b9d0ee;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 17px;
  font-weight: 500;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33.33% - 20px); /* Ensure consistent width for all cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Aligns copy button at the bottom */
  min-height: 300px; /* Set a minimum height for consistency */
  max-width: 300px; /* Ensure the max-width for the last card */
}

.address-section-profile i {
  font-size: x-large;
}

/* checking the cards are in same sizes and their alignment */
.address-card {
  margin-top: 8px;
  background-color: #8ba9cf;
  padding: 15px;
  border-radius: 20px;
  flex-grow: 1; /* Fills remaining space */
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  transition: all 0.3s ease-in-out;
}

.address-card p {
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.copy-button {
  color: white;
  border: none;
  padding: 10px 12px;
  border-radius: 5px;
  cursor: pointer;
  align-self: center; /* Centers the button */
  width: 100px; /* Fixed width for button consistency */
}

.copy-button:hover {
  background-color: #ece9e9;
  border-radius: 40px;
  
  
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
  .address-section-profile {
    flex: 1 1 calc(50% - 20px); /* Two cards per row for tablets */
  }
}

@media (min-width: 1024px) {
  .address-section-profile {
    flex: 1 1 calc(33.33% - 20px); /* Three cards per row for desktops */
  }
}
