/* General Header Styles */
.header {
  background-color: white;
  padding: 10px 20px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  border-bottom: 3px solid #1f468b;
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
  transition: background-color 0.4s ease, color 0.4s ease;
}

/* Active page styling */
.nav-item.active {
  color: #e60000;
  text-decoration: underline;
}

.header-scrolled {
  background-color: #adcae8;
  border-bottom: 3px solid #1f468b;
  color: white;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.nav-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-item {
  color: #1f468b;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
}

.nav-item:hover {
  color: #e60000;
  text-decoration: underline;
}

.nav-icons {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 180px; /* Adjusted logo size */
  height: auto;
  margin-right: 10px;
}

.profile-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #1f468b;
}

.nav-item lord-icon,
.profile-menu lord-icon {
  margin-left: 12px;
  transition: transform 0.3s ease;
}

.nav-item lord-icon:hover,
.profile-menu lord-icon:hover {
  transform: scale(1.1);
}

/* Burger menu for mobile */
.burger-menu {
  display: none; /* Hide on larger screens */
  cursor: pointer;
  font-size: 30px;
  color: #1f468b;
}

@media screen and (max-width: 768px) {
  .burger-menu {
    display: block; /* Show on small screens */
    font-size: 30px;
    cursor: pointer;
    color: #1f468b;
    padding-right: 20px;
  }

  .nav-container {
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: white;
    flex-direction: column;
    width: 100%;
    border-top: 2px solid #1f468b;
  }

  .nav-container.show-menu {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-left: 20px;
  }

  .nav-item {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .logo img {
    width: 130px; /* Adjusted logo size for mobile */
  }

  .nav-icons {
    justify-content: center;
    padding: 10px;
  }
}

/* Dropdown Menu Styles */
.dropdown {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #1f468b;
  color: black;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 1100; /* Ensure it's visible */
  width: max-content;
  max-width: 300px;
  text-align: left;
  animation: fadeIn 0.2s ease-in-out;
}

.signed-in-as {
  font-size: 14px;
  color: rgb(197, 197, 197);
  margin-bottom: 15px;
}

.signed-in-as span {
  font-weight: 400;
  color: #adcae8;
  display: block;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  word-wrap: break-word;
}

.dropdown-separator {
  border: none;
  border-top: 1px solid #adcae8;
  margin: 10px;
}

.dropdown-links {
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  font-size: 16px;
  padding: 10px 5px;
  color: #adcae8;
  font-weight: 425;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: #adcae8;
  color: #1f468b;
  font-weight: 500;
}

.sign-out {
  color: #adcae8;
  background: none;
  border: none;
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: left;
  padding: 10px 5px;
  cursor: pointer;
  display: flex;
  align-items: right;
  margin-top: 15px;
}

.sign-out i {
  margin-right: 8px;
}

.sign-out:hover {
  text-decoration: underline;
  color: aliceblue;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown p.signed-in-as span {
  max-width: 250px;
  word-wrap: break-word;
}
