/* General Container Styling */
.address-main-container {
  background-image: url('/public/images/profilebg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
}
} 
.addressbook-parent {
  background-image: url('/public/images/profilebg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  flex-direction: column;
}

.address-book-container {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-image: url('/public/images/container_bg2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Center the welcome section */
.welcome-section-address {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
  width: 100%;
}

.welcome-section-address h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #0f2853;
  border-radius: 50px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 3px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-image: url('/public/images/addressheadbg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40vh;
  width: 100%;
}

/* Address card for registered address */

.address-card-H {
  font-size: 25px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  background-image: url('/public/images/loacationbg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  border-radius: 50px;
  margin: 20px auto;
  width: 60%;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  text-align: center;
 
}
.input-flex{
  display: flex;
  flex-direction: row;

}



.address-card-H h2 {
  color: #0f2853;
}

.address-card-H p {
  font-size: 14px;
  color: #000000;
}

/* Shipping Address Container */
.shipping-addresses {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three cards per row */
  gap: 20px;
  justify-items: center;
  width: 100%;
  padding: 20px 0;
}

.shipping-addresses.single-address {
  grid-template-columns: 1fr; /* One address takes full row */
}

.shipping-addresses.two-addresses {
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  justify-content: center;
  
}

/* Shipping Address Card */
.address-card-book {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  min-height: 300px;
}

.address-card-book.single {
  max-width: 500px;
  min-height: 350px;
}

/* Info Box Styling */
.info-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #d41313;
  color: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 125%;
}

.info-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.info-icon svg {
  fill: #ffffff;
}

.info-box p {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.flag-icon {
  width: 70px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

.address-details p {
  margin: 5px 0;
  font-size: 13px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  text-align: center;
  color: #333;
}

/* Edit Button Styling */

.edit-btn {
  background-color: #0077b6;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.edit-btn:hover {
  background-color: #d41313;
}

/* Delete Icon */
.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2em;
  color: red;
  cursor: pointer;
}

/* Add Address Button */
.AddNewButton {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: relative;
  background-color: #11af4e;
  width: 20%;
  border-radius: 30px;
}

.AddNewButton p {
  font-size: 16px;
  color: white;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.add-address-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 80px;
  cursor: pointer;
  font-size: 1.1em;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.add-address-btn:hover {
  background-color: #0a801a;
}

/* Modal Styling */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  border-top: 8px solid #0056b3;
  background-color: white;
  padding: 30px;
  border-radius: 45px;
  width: 700px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}


.modal-header {
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    
    
    margin-bottom: 20px;
    position: relative; /* Ensures close button stays inside */
  }
}

.modal-header h2 {
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #0056b3;
  margin-bottom: 30px;
  font-weight: 700;
  
}

.close-icon {
  cursor: pointer;
  font-size: 1.5em;
  color: #0077b6;
  position: relative;
  margin-left: auto;
  right: 10px; /* Adjust positioning to your preference */
  top: 0;
}

/* Input Fields Styling */
.input-group-address {
  margin-bottom: 20px;
  
}

.input-group-address label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #104163;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  
}

.input-group-address input,
.input-group-address select {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #dcdcdc;
  border-radius: 25px;
  
}

.input-group-address input:focus,
.input-group-address select:focus {
  border-color: #252627;
  border-width: 2px;
  outline: none;
}


/* Enhanced Add Address Button */
.formBtns{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
}
.submitFormBtn{
  background-color: #0056B3;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  padding: 10px 20px;
  width: 30%;
  border-radius: 30px;
  
  transition: background-color 0.3s ease;
}
.submitFormBtn:hover{
  background-color: #0485db;
}
.closeFormBtn{
  background-color: #5f5f5f;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  padding: 10px 20px;
  width: 30%;
  border-radius: 30px;
  
  transition: background-color 0.3s ease;
}
.closeFormBtn:hover{
  background-color: #dd3f3f;
}

/* Responsive Design */

/* Tablet Devices (768px to 1024px) */  
@media (max-width: 1024px) {
  .shipping-addresses {
    grid-template-columns: repeat(2, 1fr);
  }

  .welcome-section-address h1 {
    font-size: 30px;
  }

  .address-card-H {
    width: 70%;
  }

  .AddNewButton {
    width: 30%;
  }
}

/* Mobile Devices (max-width: 600px) */
@media (max-width: 600px) {
  .shipping-addresses {
    grid-template-columns: 1fr;
  }
 
  .address-card-book {
    
    flex-direction: column;
    align-items: flex-start;
    max-width: 90%;
    margin-top: 0px;
  }

  .welcome-section-address h1 {
    font-size: 30px;
  }

  .address-card-H {
    width: 90%;
  }


}

/* Small Mobile Devices (max-width: 600px) */
@media (max-width: 600px) {
  .modal-content {
    width: 90%;
  }
  .address-card-book.single {
    max-width: 220px;
    min-height: 270px;
    .edit-btn {
      margin-left: 70px;
    }
  }
 
  .edit-btn {
    margin-top: 10px;
    margin-left: 50px;
  }

  .welcome-section h1 {
    font-size: 1.8em;
  }

  .address-card-H {
    width: 100%;
    padding: 10px;
  }

  .modal-header h2 {
    font-size: 1.4em;
  }

  .submit-btn {
    font-size: 1em;
  }



  
}
.info-box-address {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #E64032;
  color: #ffffff;
  border-radius: 20px;
  padding: 10px;
  margin-top:-20px;
  margin-bottom: 60px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  
}

.info-icon-address {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.info-icon-address svg {
  fill: #ffffff;
}

.info-box-address p {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.modal-body {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
}

.modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #0077b6;
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}


/*Responsiveness*/
@media (max-width: 480px){
  .info-box-address {
    flex-direction: column; /* Stack items vertically for smaller screens */
    padding: 5px; /* Increase padding for easier tapping on mobile */
    margin-top: -85px ;
  }
  .AddNewButton{
    width: 70%;
    padding: 5px 5px;
    margin-top: -10px;
  }
}

@media screen and (max-width: 600px) {
  .info-box-address {
    flex-direction: column; /* Stack items vertically for smaller screens */
    width: 40%; /* Adjust width to fit within mobile screens */
    top: 99%; /* Adjust the top position for better fit on mobile screens */
    padding: 10px; /* Increase padding for easier tapping on mobile */
  }

  .info-box-address p {
    font-size: 12px; /* Reduce font size for better readability on smaller screens */
    text-align: center; /* Center the text for better alignment on mobile */
  }
}
